 <template>
	<div class="attr-management" ref="attrManagement">
		<div class="form-area" ref="form">
			<el-form
				:inline="true"
				:model="form"
				class="demo-form-inline"
				label-position="left"
				label-width="72px"
			>
				<el-form-item label="规格名称：">
					<el-input v-model.trim="form.name" placeholder="请输入规格名称"></el-input>
				</el-form-item>
				<el-form-item label="所属公司：">
					<companySelect @getValue="getGroupId" />
				</el-form-item>
				<el-form-item label="状态：">
					<el-select v-model="form.status" clearable placeholder="请选择状态">
						<el-option label="正常" :value="0"></el-option>
						<el-option label="锁定" :value="1"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" size="small" @click="onSearch">查询</el-button>
					<el-button type="primary" size="small" @click="onAddAttr">新增</el-button>
				</el-form-item>
			</el-form>
		</div>
		<el-table
			:data="tableData"
			style="width: 100%"
			row-key="id"
			border
            stripe
            :height="tableHeight"
		>
			<el-table-column type="expand">
				<template slot-scope="scope">
					<el-table :data="scope.row.values" style="width: 100%" row-key="id" border>
						<el-table-column prop="id" label="规格值编号"></el-table-column>
						<el-table-column prop="name" label="规格值名称"></el-table-column>
						<el-table-column prop="createTime" label="创建时间"></el-table-column>
						<el-table-column prop="status" label="是否锁定状态">
							<template slot-scope="scope">
								<el-switch
									@change="changeValuesStatus($event,scope.row)"
									v-model="scope.row.status"
									:active-value="1"
									:inactive-value="0"
								></el-switch>
							</template>
						</el-table-column>
						<el-table-column label="操作">
							<template slot-scope="scope">
								<el-button
									icon="el-icon-edit-outline"
									type="text"
									size="small"
									sort="primary"
									@click="onEditAttrValues(scope.$index,scope.row)"
								>编辑</el-button>
							</template>
						</el-table-column>
					</el-table>
				</template>
			</el-table-column>
			<el-table-column prop="id" label="规格编号"></el-table-column>
			<el-table-column prop="name" label="名称"></el-table-column>
			<el-table-column prop="createTime" label="创建时间"></el-table-column>
			<el-table-column prop="status" label="是否锁定状态">
				<template slot-scope="scope">
					<el-switch
						@change="changeStatus($event,scope.row)"
						v-model="scope.row.status"
						:active-value="1"
						:inactive-value="0"
					></el-switch>
				</template>
			</el-table-column>
			<el-table-column label="操作">
				<template slot-scope="scope">
					<el-button
						icon="el-icon-edit-outline"
						type="text"
						size="small"
						sort="primary"
						@click="onEditAttr(scope.row)"
					>编辑</el-button>
					<el-button
						icon="el-icon-plus"
						type="text"
						size="small"
						sort="authorize"
						@click="onAddChildAttr(scope.row)"
					>新增规格值</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="pagination" ref="pagination">
			<el-pagination
				background
				layout="total, sizes, prev, pager, next, jumper"
				:total="total"
				:page-size="form.pageSize"
				:current-page="form.currentPage"
				@size-change="onSizeChange"
				@current-change="onCurrentChange"
			></el-pagination>
		</div>
		<el-dialog :title="title" v-if="dialogVisible" :visible.sync="dialogVisible" width="30%">
			<div>
				<div class="body"></div>
				<addAttr
					ref="addAttr"
					:attrId="attrId"
					:title="title"
					:loadData="onSearch"
					@onDialogClose="onDialogClose"
				/>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="default" size="small" @click="dialogVisible = false">取消</el-button>
				<el-button
					type="primary"
					size="small"
					@click="onSave"
				>{{ title.indexOf("新增") !== -1 ? "新增" : "保存"}}</el-button>
			</span>
		</el-dialog>
		<el-dialog :title="title" v-if="dialogVisibleVa" :visible.sync="dialogVisibleVa" width="30%">
			<div>
				<div class="body"></div>
				<addAttrValues
					ref="addAttrValues"
					:attrId="attrId"
					:attrValuesId="attrValuesId"
					:title="title"
					:loadData="onSearch"
					@onDialogClose="onDialogCloseValues"
				/>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="default" size="small" @click="dialogVisibleVa = false">取消</el-button>
				<el-button
					type="primary"
					size="small"
					@click="onSaveVa"
				>{{ title.indexOf("新增") !== -1 ? "新增" : "保存"}}</el-button>
			</span>
		</el-dialog>
	</div>
</template>

  <script>
import addAttr from "./components/addAttr";
import addAttrValues from "./components/addAttrValues";
import companySelect from "@/components/treeSelect/companySelect.vue";

import { getProdsAttrsPage, updateProdsAttrsStatus, updateProdsAttrsVS} from "@/api/lib/api.js";
export default {
	name: "attrManagement",
	components: {
		addAttr,
        companySelect,
        addAttrValues,
	},
	data() {
		return {
			tableHeight: 0,
			total: 0,
			tableData: [],
			form: {
				name: "",
				deptId: "",
				status: null,
				current: 1,
				size: 10,
			},
			title: "",
			attrId: null,
			attrValuesId: null,
			dialogVisible: false,
			dialogVisibleVa: false,
		};
	},
	methods: {
		// 计算表格高度
		computeHeight() {
			let wholeHeight = this.$refs.attrManagement.clientHeight;
			let formHeight = this.$refs.form.clientHeight;
			let paginationHeight = this.$refs.pagination.clientHeight;
			this.tableHeight =
				wholeHeight - 16 - formHeight - paginationHeight - 10 - 16 - 35;
		},
		/**获取企业id */
		getGroupId(v) {
			this.form.deptId = v;
		},
		// 点击查询
		onSearch() {
			this.form.current = 1;
			this.getAttrListByField();
		},
		//获取数据
		getAttrListByField() {
			getProdsAttrsPage(this.form).then((res) => {
				if (res.code === 0) {
					this.tableData = res.data.records;
					this.total = res.data.total;
					if (!res.data.records.length && this.form.current !== 1) {
						//如果当前页的数据为0则设置页面为前一页 但是确保当前页不为1
						self.form.current -= 1;
						self.getAttrListByField();
					}
				} else {
					this.tableData = [];
					this.total = 0;
					this.$message.error(res.message);
				}
			});
		},
		//新增
		onAddAttr() {
			this.title = "新增规格";
			this.dialogVisible = true;
		},
		//新增规格值
		onAddChildAttr(row) {
			this.title = "新增规格值";
			this.attrId = row.id;
			this.dialogVisibleVa = true;
		},
		//编辑规格值
		onEditAttrValues(index,row) {
            this.title = "编辑规格值";
            this.attrValuesId = row.id;
            this.attrId=row.attrId;
			this.dialogVisibleVa = true;
		},
		onEditAttr(row) {
			this.title = "编辑规格";
			this.attrId = row.id;
			this.dialogVisible = true;
		},
		// 修改状态
		changeStatus(e, row) {
			let message = e === 1 ? "锁定" : "启用";
			updateProdsAttrsStatus({ id: row.id, status: e }).then((res) => {
				if (res.code === 0) {
					this.$message.success(`${message}成功`);
				} else {
					this.$message.error(`${message}失败`);
					row.status = e === 1 ? 0 : 1;
				}
			});
        },
        //修改状态
        changeValuesStatus(e, row) {
			let message = e === 1 ? "锁定" : "启用";
			updateProdsAttrsVS({ id: row.id, status: e }).then((res) => {
				if (res.code === 0) {
					this.$message.success(`${message}成功`);
				} else {
					this.$message.error(`${message}失败`);
					row.status = e === 1 ? 0 : 1;
				}
			});
        },
		// 当前页码改变
		onCurrentChange(page) {
			this.form.current = page;
			this.getAttrListByField(this.form);
		},
		// 当前条数改变
		onSizeChange(size) {
			this.form.size = size;
			this.getAttrListByField(this.form);
		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		// 弹窗关闭时的回调
		onDialogClose(flag) {
			this.dialogVisible = false;
			if (flag) {
				this.getAttrListByField(this.form);
			}
        },
        onDialogCloseValues(){
            this.dialogVisibleVa = false;
        },
		onSave() {
			this.$refs.addAttr.onSave();
		},
		//保存规格值
		onSaveVa() {
			this.$refs.addAttrValues.onSave();
		},
	},
	created() {
		this.onSearch();
	},
	mounted() {
		this.$nextTick(() => {
			this.computeHeight();
			window.addEventListener("resize", this.computeHeight, false);
		});
	},
	destroyed() {
		window.removeEventListener("resize", this.computeHeight);
	},
};
</script>
<style lang="scss" scoped>
.attr-management {
	width: 100%;
	height: 100%;
	border-radius: 4px;
	padding: 16px 20px;
	box-sizing: border-box;
	.avatarImg {
		vertical-align: middle;
		border-radius: 50%;
	}
}

.theme-light {
	.attr-management {
		background: #ffffff;
		box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
	}
}
</style>