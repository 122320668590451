<template>
	<div class="add-attr">
		<el-form
			:model="addForm"
			:rules="rules"
			ref="addForm"
			label-width="82px"
			label-position="left"
			class="demo-form dialog-form"
		>
			<!-- 规格名称： -->
			<el-form-item label="规格名称：" prop="name">
				<el-input v-model.trim="addForm.name" placeholder="请输入规格名称"></el-input>
			</el-form-item>
			<!-- 所属部门： -->
			<el-form-item label="所属部门：" prop="deptId">
				<companySelect :value="companyId" @getValue="getGroupId" />
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
import {
	insertProdsAttrs,
	updateProdsAttrsStatus,
	updateProdsAttrs,
	insertProdsAttrsVlues,
    updateProdsAttrsVlues,
    getProdsAttrsById,
    getProdsAttrsValuesById
} from "@/api/lib/api.js";
import companySelect from "@/components/treeSelect/companySelect.vue";

export default {
	components: {
		companySelect,
	},
	props: {
		title: {
			type: String,
			default: "",
		},
		attrId: {
			type: String,
			default: null,
		},
		loadData: {
			type: Function,
			default: null,
		},
	},
	data() {
		return {
			addForm: {
				name: "",
				deptId: "",
			},
			rules: {
				name: [
					{
						required: true,
						message: "请输入规格名称",
						trigger: "blur",
					},
				],
				deptId: [
					{
						required: true,
						message: "请输入选择企业",
						trigger: "blur",
					},
				],
            },
            companyId:'',
		};
	},
	mounted() {},
	methods: {
		/**获取企业id */
		getGroupId(v) {
			this.addForm.deptId = v;
		},
		onSave() {
			this.$refs.addForm.validate((valid) => {
				if (valid) {
					let data = {
						name: this.addForm.name,
						deptId: this.addForm.deptId,
					};
					if (this.title.indexOf("新增") !== -1) {
						data = {
							...data,
						};
						insertProdsAttrs(data).then((res) => {
							if (res.code === 0) {
								this.$message.success("新增成功");
								this.loadData();
							} else {
								this.$message.error("新增失败");
							}
						});
					} else {
						data = {
							...data,
							id: this.attrId,
						};
						updateProdsAttrs(data).then((res) => {
							if (res.code === 0) {
								this.$message.success("修改成功");
								this.loadData();
							} else {
								this.$message.error("修改失败");
							}
						});
					}
					this.$emit("onDialogClose");
				}
			});
		},
    },
     created(){
        if(this.attrId){
            getProdsAttrsById(this.attrId).then(res=>{
                if(res.code===0){
                    this.addForm={...res.data}
                    this.companyId=res.data.deptId
                }
            })
        }
    }
   
};
</script>
<style lang="scss">
</style>
